<template>
  <div class="map">
    <div id="map" style="width: 100%; height: 100vh"></div>
  </div>
</template>

<script>
import Vue from 'vue';

const script = document.createElement('script');
script.type = 'text/javascript';
script.src = './js/script-mapa.js';
document.body.appendChild(script);

export default Vue.extend({
  name: 'Map',
  data: () => ({})
});
</script>

<style>
body {
  cursor: none;
}

.mapboxgl-canvas-container {
  cursor: none !important;
}
</style>
